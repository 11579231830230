<template>
  <div class="statusRootWrap">
    <div class="title">设备状态概览</div>
    <div class="stateUpdateTime">评估时间:{{ assessTime }}</div>
    <div class="fenkai" style="display:block;">
      <div class="pieOne"></div>
      <div class="pieTwo"></div>
      <div class="pieThree"></div>
      <div class="pieFour"></div>
      <div class="pieFive"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getItem } from '../../api/index'
export default {
  name: 'StateOverview',
  data() {
    return {
      // 图表设置数据
      chartOption: {},
      oneChart: null,
      twoChart: null,
      threeChart: null,
      fourChart: null,
      fiveChart: null,
      // 评估时间
      assessTime: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initEchartInstance()
      let data = { userCode: getItem('userData').userCode }
      // 获取风险等级数据并渲染饼图
      this.renderEcharts(data)
    })
  },
  methods: {
    myResize() {
      this.oneChart.resize()
      this.twoChart.resize()
      this.threeChart.resize()
      this.fourChart.resize()
      this.fiveChart.resize()
    },
    // 初始化环形图实例
    initEchartInstance() {
      let oneChartDom = document.getElementsByClassName('pieOne')[0]
      let twoChartDom = document.getElementsByClassName('pieTwo')[0]
      let threeChartDom = document.getElementsByClassName('pieThree')[0]
      let fourChartDom = document.getElementsByClassName('pieFour')[0]
      let fiveChartDom = document.getElementsByClassName('pieFive')[0]
      this.oneChart = echarts.init(oneChartDom)
      this.twoChart = echarts.init(twoChartDom)
      this.threeChart = echarts.init(threeChartDom)
      this.fourChart = echarts.init(fourChartDom)
      this.fiveChart = echarts.init(fiveChartDom)
    },
    // 初始化环形图状态数据
    async renderEcharts(reqData) {
      // const res = await this.$http.post(this.$urlObj.queryEquipmentSummaryStatus, reqData)
      // if (res.status == 200 && res.data.resultCode == '0000') {
      //   let { riskLevelOverview, equipmentCount, assessTime } = res.data.data
      //   this.assessTime = assessTime
      //   for (let i = 0; i < riskLevelOverview.length; i++) {
      //     if (riskLevelOverview[i].riskLevel == 0) {
      //       this.oneChart.setOption(this.getoptions('健康', ['#35C78C', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
      //     }
      //     if (riskLevelOverview[i].riskLevel == 1) {
      //       this.twoChart.setOption(this.getoptions('轻度', ['#8bd26c', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
      //     }
      //     if (riskLevelOverview[i].riskLevel == 2) {
      //       this.threeChart.setOption(this.getoptions('中度', ['#f8de42', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
      //     }
      //     if (riskLevelOverview[i].riskLevel == 3) {
      //       this.fourChart.setOption(this.getoptions('重度', ['#f79f3d', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
      //     }
      //     if (riskLevelOverview[i].riskLevel == 4) {
      //       this.fiveChart.setOption(this.getoptions('超限', ['#f54b36', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
      //     }
      //   }
      // }
      const res = await this.$http.post(this.$urlObj.queryEquip, reqData)
      if (res.status == 200 && res.data.resultCode == '0000') {
        // let { riskLevelOverview, equipmentCount, assessTime } = res.data.data
        let equipData = res.data.data
        let equipmentCount = equipData.length;
        let riskLevelOverview = []
        let riskLevelCountArr = [0, 0, 0, 0, 0]

        let { personalityConfig } = getItem('userData')
        let riskLevelArr = []
        if (personalityConfig && personalityConfig.length) {
          personalityConfig.forEach(item => {
            if (item.configType == 'EquipmentRiskLevel') {
              riskLevelArr.push(item)
            }
          })
        }
        let equipmentCodeArr = []
        equipData.forEach(item => {
          equipmentCodeArr.push(item.equipmentCode)
        })
        let currentTimeStamp = new Date().getTime()
        riskLevelArr.forEach(item => {
          let isInRiskLevelArr = equipmentCodeArr.includes(item.userPersonalityConfig.equipmentCode)
          let isValid = new Date(item.userPersonalityConfig.invalidTime + ' 23:59:59').getTime() > currentTimeStamp
          if (isInRiskLevelArr && isValid) {
            equipData.forEach(item1 => {
              if (item1.equipmentCode == item.userPersonalityConfig.equipmentCode) {
                item1.riskLevel = item.userPersonalityConfig.riskLevel
              }
            })
          }
        })

        equipData.forEach(item => {
          if (item.riskLevel == 0) riskLevelCountArr[0]++
          if (item.riskLevel == 1) riskLevelCountArr[1]++
          if (item.riskLevel == 2) riskLevelCountArr[2]++
          if (item.riskLevel == 3) riskLevelCountArr[3]++
          if (item.riskLevel == 4) riskLevelCountArr[4]++
        })
        for (let i = 0; i < 5; i++) {
          let obj = {
            riskLevel: i,
            count: riskLevelCountArr[i]
          }
          riskLevelOverview.push(obj)
        }
        // this.assessTime = assessTime
        for (let i = 0; i < riskLevelOverview.length; i++) {
          if (riskLevelOverview[i].riskLevel == 0) {
            this.oneChart.setOption(this.getoptions('健康', ['#35C78C', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
          }
          if (riskLevelOverview[i].riskLevel == 1) {
            this.twoChart.setOption(this.getoptions('轻度', ['#8bd26c', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
          }
          if (riskLevelOverview[i].riskLevel == 2) {
            this.threeChart.setOption(this.getoptions('中度', ['#f8de42', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
          }
          if (riskLevelOverview[i].riskLevel == 3) {
            this.fourChart.setOption(this.getoptions('重度', ['#f79f3d', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
          }
          if (riskLevelOverview[i].riskLevel == 4) {
            this.fiveChart.setOption(this.getoptions('超限', ['#f54b36', '#DAE1E9'], riskLevelOverview[i], equipmentCount))
          }
        }
      }
    },
    // 获取单个项目的所有设备的状态数据
    async getOneProjectState(params) {
      let reqData = {
        userCode: getItem('userData').userCode,
        projectCode: params.projectCode
      }
      this.renderEcharts(reqData)
    },
    // 获取所有项目的所有设备的状态数据
    async getAllProjectState() {
      let reqData = {
        userCode: getItem('userData').userCode
      }
      this.renderEcharts(reqData)
    },

    // 获取图表数据

    getoptions(title, colorList, riskLevelOverview, equipmentCount) {
      let obj = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: title,
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'normal'
          },
          left: 'center',
          top: '80%'
        },
        media: [
          {
            query: { minWidth: 100 },
            option: {
              title: {
                top: 'bottom'
              }
            }
          },
          { option: { title: { top: '80%' } } }
        ],
        series: [
          {
            type: 'pie',
            radius: ['80%', '90%'],
            label: {
              position: 'center',
              formatter: equipmentCount ? ((riskLevelOverview.count / equipmentCount) * 100).toFixed() + '%' : '0%',
              color: '#fff',
              fontSize: '14px'
            },
            silent: true,
            labelLine: {
              show: false
            },
            data: [
              {
                value: riskLevelOverview.count,
                itemStyle: { color: equipmentCount ? colorList[0] : '#DAE1E9' }
              },
              {
                value: equipmentCount - riskLevelOverview.count,
                itemStyle: { color: equipmentCount ? colorList[1] : '#DAE1E9' }
              }
            ]
          }
        ]
      }
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
.statusRootWrap {
  position: relative;
  height: 100%;
  width: 100%;

  .title {
    position: absolute;
    left: 18px;
    top: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    color: #fff;
  }

  .stateUpdateTime {
    color: #555c7e;
    position: absolute;
    right: 16px;
    top: 21px;
    font-size: 14px;
  }

  @media screen and (min-width: 500px) {
    .stateUpdateTime {
      font-size: 16px;
    }
  }

  .pieRootWrap {
    width: 89.47%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .commonStyle {
      width: 120px;
      height: 120px;
      float: left;
      margin-left: 20px;
    }

    .healthy {
      margin-left: 0;
    }
  }

  .fenkai {
    width: 89.47%;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);

    // z-index: 999;
    div {
      width: 20%;
      height: 100%;
      float: left;
    }
  }

  .stateText {
    position: absolute;
    left: 0;
    top: 232px;
    padding-left: 13px;

    li {
      float: left;
      margin-left: 64px;
      margin-right: 41px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
  }

  .common {
    color: #e8e8e8;
    font-size: 24px;
    position: absolute;
    top: 147px;
  }

  .health {
    left: 80px;
  }

  .light {
    left: 220px;
  }

  .middle {
    left: 352px;
  }

  .hard {
    left: 492px;
  }

  .overrun {
    left: 630px;
  }
}
</style>
